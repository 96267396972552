import { Button, Modal, Popover, Select, Tag, Tooltip } from "antd";
import { generateClient } from "aws-amplify/api";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useState } from "react";
import { Project } from "../../API";
import { getProject } from "../../graphql/queries";
import { AsyncOpState } from "../../stores/async-op-state";
import { EXAMPLE_PLAYER_LINK, getENV } from "../../stores/consts";
import { useMst } from "../../stores/main";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import PlayerManager from "../../helpers/playerManager";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import { IFlowScene } from "@blings/blings-player/lib/src/SDK/sdk.api";
import { theme } from "../../theme";

const client = generateClient();

export const PublishButtons = observer(() => {
  const {
    modsStore: { hasUnsavedChanges: hasUnsavedModsChanges },
    platformStore: {
      publishCurrentVersion,
      selectedProjectId,
      selectedProjectBasicInfo,
      updateVideoPartMods,
      publishedTime,
      projectWorkspaceVersion,
      savePerVideoSchema,
      saveLiveControlSchema,
      selectedProjectHasLiveVersion,
      selectedProjectIsLatestVersionPublished,
    },
    dynamicDataStore: {
      hasUnsavedChanges: hasUnsavedDynamicDataChanges,
      perVideoCurrentSchema,
      liveControlCurrentData,
      liveControlCurrentSchema,
    },
  } = useMst();
  const [publishButtonState, setPublishButtonState] = useState<AsyncOpState>(
    AsyncOpState.Untouched
  );
  const [modalUnsavedText, setModalUnsavedText] = useState("");
  const [modalSaveOpen, setModalSaveOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [project, setProject] = useState<Project | null>(null);
  const [scenes, setScenes] = useState<Array<IFlowScene>>([]);
  const [isSaving, setIsSaving] = useState(false);
  const publishButtonTexts = {
    [AsyncOpState.Untouched]: "Published",
    [AsyncOpState.Changed]: "Publish",
    [AsyncOpState.Saving]: "Publishing",
    [AsyncOpState.Success]: "Published",
    [AsyncOpState.Error]: "Error",
  };
  useEffect(() => {
    if (selectedProjectId) {
      setScenes([]);
      getPublishedVideoPartsNamesAndData();
    }
  }, [selectedProjectId, selectedProjectBasicInfo]);

  useEffect(() => {
    if (isSaving) {
      setPublishButtonState(AsyncOpState.Saving);
    } else if (
      hasUnsavedDynamicDataChanges ||
      hasUnsavedModsChanges ||
      !selectedProjectIsLatestVersionPublished
    ) {
      setPublishButtonState(AsyncOpState.Changed);
    } else {
      setPublishButtonState(AsyncOpState.Untouched);
    }
  }, [
    selectedProjectIsLatestVersionPublished,
    hasUnsavedModsChanges,
    hasUnsavedDynamicDataChanges,
    isSaving,
  ]);
  // check has unsaved changes
  useEffect(() => {
    setHasUnsavedChanges(
      !selectedProjectIsLatestVersionPublished ||
        hasUnsavedModsChanges ||
        hasUnsavedDynamicDataChanges
    );
  }, [
    selectedProjectIsLatestVersionPublished,
    hasUnsavedModsChanges,
    hasUnsavedDynamicDataChanges,
    selectedProjectBasicInfo?.updatedAt,
    projectWorkspaceVersion?.updatedAt,
  ]);
  function publishButtonClick() {
    if (hasUnsavedDynamicDataChanges && hasUnsavedModsChanges) {
      setModalUnsavedText("You have unsaved changes for data and connectors");
    } else if (hasUnsavedDynamicDataChanges) {
      setModalUnsavedText("You have unsaved data changes");
    } else if (hasUnsavedModsChanges) {
      setModalUnsavedText("You have unsaved connectors changes");
    } else {
      setModalSaveOpen(true);
    }
  }
  async function getPublishedVideoPartsNamesAndData() {
    const modifiedGetProject = insertIntoGraphQlString(
      insertIntoGraphQlString(getProject, {
        path: ["flowDiagram"],
        key: "nodes",
        value: {
          id: true,
          label: true,
          data: true,
          lineupId: true,
          type: true,
        },
      }),
      {
        path: ["flowDiagram"],
        key: "edges",
        value: { source: true, target: true, sourceHandler: true },
      }
    ).replaceAll("__typename", "");
    const {
      data: { getProject: project },
    } = (await client.graphql({
      query: modifiedGetProject,
      variables: { id: selectedProjectId as string },
    })) as { data: { getProject: Project } };
    setProject(project);
  }
  async function publish() {
    setPublishButtonState(AsyncOpState.Saving);
    try {
      await publishCurrentVersion();
    } catch (e) {
      console.log(e);
    }
    setPublishButtonState(AsyncOpState.Untouched);
    setModalSaveOpen(false);
  }
  async function saveChangesAndPublish() {
    setIsSaving(true);
    setPublishButtonState(AsyncOpState.Saving);
    if (hasUnsavedModsChanges) await updateVideoPartMods();
    if (hasUnsavedDynamicDataChanges) {
      if (perVideoCurrentSchema) {
        await savePerVideoSchema(JSON.parse(perVideoCurrentSchema));
      } else {
        throw new Error("perVideoCurrentSchema is null");
      }
      if (liveControlCurrentSchema && liveControlCurrentData) {
        await saveLiveControlSchema(
          JSON.parse(liveControlCurrentSchema),
          liveControlCurrentData
        );
      } else {
        throw new Error(
          "liveControlCurrentSchema or liveControlCurrentData is null"
        );
      }
    }
    await publish();
    setModalUnsavedText("");
    setIsSaving(false);
  }
  function openLiveVersion() {
    let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${selectedProjectId}`;
    if (scenes) {
      url += `&scenes=${encodeURIComponent(
        scenes
          .map((scene) =>
            typeof scene === "string" ? scene : JSON.stringify(scene)
          )
          .join()
      )}`;
    }
    const publishedExampleData = project?.stateJsonSchemaStr
      ? JSON.parse(project.stateJsonSchemaStr).examples[0]
      : null;
    if (publishedExampleData) {
      url += `&data=${encodeURIComponent(
        JSON.stringify(publishedExampleData)
      )}`;
    }
    const version = PlayerManager.get().GetCurrentPlayerVersion();
    if (version && version !== "WARN_NO_PLAYER_VERSION") {
      url += `&v=${version}`;
    }
    window.open(url, "_blank");
  }
  const lineups = project?.flowDiagram?.nodes.reduce<Array<string>>(
    (acc, node) => {
      if (node.lineupId) {
        acc.push(node.lineupId);
      }
      return acc;
    },
    []
  );
  return (
    <>
      <div className="PublishButtons">
        <Popover
          overlayClassName="view-live-version-popover"
          placement="bottomRight"
          title="Add scenes"
          content={
            <div>
              <Select
                id="view-live-select"
                className="view-live-version-select"
                style={{ marginBottom: "1rem" }}
                value={scenes.map((scene) =>
                  typeof scene === "string" ? scene : JSON.stringify(scene)
                )}
                tagRender={tagRender}
                onChange={(value) => {
                  value = value.map((v) => {
                    try {
                      return JSON.parse(v);
                    } catch (e) {}
                    return v;
                  });
                  setScenes(value);
                }}
                mode="multiple"
              >
                {lineups?.length ? (
                  <>
                    <Select.OptGroup key="Flows">
                      {lineups.map((lineup) => (
                        <Select.Option
                          key={lineup}
                          value={JSON.stringify({ lineup })}
                        >
                          {lineup}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup key="Scenes">
                      {project?.videoParts?.map((scene) => (
                        <Select.Option key={scene.name} value={scene.name}>
                          {scene.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </>
                ) : (
                  project?.videoParts?.map((scene) => (
                    <Select.Option key={scene.name} value={scene.name}>
                      {scene.name}
                    </Select.Option>
                  ))
                )}
              </Select>
              <Button type="primary" onClick={openLiveVersion}>
                Go
              </Button>
            </div>
          }
          trigger="click"
        >
          {selectedProjectHasLiveVersion ? <a>View published version</a> : ""}
        </Popover>

        <Tooltip
          placement="left"
          title={publishedTime}
          overlayStyle={{ maxWidth: "500px" }}
        >
          <div>
            <BlingsBtn
              disabled={!hasUnsavedChanges || isSaving}
              opState={publishButtonState}
              htmlType={"submit"}
              btnTexts={publishButtonTexts}
              onClick={() => {
                publishButtonClick();
              }}
            />
          </div>
        </Tooltip>
      </div>
      <Modal
        open={modalUnsavedText !== ""}
        onCancel={() => {
          setModalUnsavedText("");
        }}
        footer={[
          <div
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <a
              key="ok"
              type="primary"
              onClick={() => {
                setModalUnsavedText("");
              }}
            >
              Close
            </a>
            <BlingsBtn
              key="publish"
              className={"publish"}
              opState={publishButtonState}
              htmlType={"submit"}
              btnTexts={{
                [AsyncOpState.Changed]: "Save and Publish",
              }}
              onClick={saveChangesAndPublish}
            />
          </div>,
        ]}
      >
        <div className="popup-text">{modalUnsavedText}</div>
      </Modal>
      <Modal
        open={modalSaveOpen}
        onCancel={() => {
          setModalSaveOpen(false);
        }}
        footer={[
          <Button
            key="cancel"
            type="link"
            onClick={() => {
              setModalSaveOpen(false);
            }}
          >
            <p className="textbutton">Cancel</p>
          </Button>,
          <BlingsBtn
            key="publish"
            className={"publish"}
            opState={publishButtonState}
            htmlType={"submit"}
            btnTexts={publishButtonTexts}
            onClick={publish}
          />,
        ]}
      >
        <div className="popup-text">
          Are you sure you want to publish the current version?
        </div>
      </Modal>
    </>
  );
});
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  // const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
  const isLineup =
    value &&
    (() => {
      try {
        return JSON.parse(value).lineup != null;
      } catch (e) {}
      return false;
    })();
  return (
    <Tag
      color={isLineup ? theme.primary : undefined}
      // onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};
