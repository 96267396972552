import React, { useState } from "react";
import { Col, Input, Row, Select } from "antd";
import {
  modTypes,
  interactiveGotoTypes,
  IInteractiveModGoto,
} from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import Text from "antd/lib/typography/Text";
import { DynamicValueMod, DynamicValueModTypes } from "../../DynamicValueMod";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import MainCTAButton from "../../../MainCTA/MainCTAButton";
import { useMst } from "../../../../stores/main";
import { ExperimentOptimizationTechnique } from "../../../../API";

export enum interactiveActionTypes {
  jumpToFrame = "jumpToFrame",
  jumpToMarker = "jumpToMarker",
}

function RenderGoToOptions(props: ModsPanelCompProps<IInteractiveModGoto>) {
  const { change } = props;
  const {
    experimentStore: { selectedExperimentOptimizationTechnique },
  } = useMst();
  const hasVariantsEnabled =
    selectedExperimentOptimizationTechnique &&
    selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;
  const [actionState, setActionState] = useState<
    interactiveActionTypes | undefined
  >(
    (() => {
      switch (change.type) {
        case modTypes.interactiveGoto:
          return (change as IInteractiveModGoto).gotoType ===
            interactiveGotoTypes.marker
            ? interactiveActionTypes.jumpToMarker
            : interactiveActionTypes.jumpToFrame;
      }
    })()
  );

  function renderActionForm(props: ModsPanelCompProps<IInteractiveModGoto>) {
    switch (actionState) {
      case interactiveActionTypes.jumpToFrame:
        return (
          <div>
            <DynamicValueMod<modTypes.interactiveGoto>
              change={props.change}
              onChange={(index, keys, values) => {
                props.onChange(
                  index,
                  (keys as Array<keyof IInteractiveModGoto>).concat(
                    "type",
                    "gotoType"
                  ),
                  values.concat(
                    modTypes.interactiveGoto,
                    interactiveGotoTypes.frame
                  )
                );
              }}
              index={props.index}
              type={DynamicValueModTypes.number}
            />
          </div>
        );
      case interactiveActionTypes.jumpToMarker:
        return (
          <Select
            style={{ width: "100%" }}
            options={props.json?.markers.map((m) => ({
              label: m.cm,
              value: m.cm,
            }))}
            onChange={(v) => {
              props.onChange(
                props.index,
                ["value", "type", "gotoType"],
                [v, modTypes.interactiveGoto, interactiveGotoTypes.marker]
              );
            }}
          />
        );
      default:
        return;
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Text type="secondary">Give this button a name [Optional]</Text>
          {/* <InfoTooltip info="" /> */}
          <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Input
              size={"large"}
              id="connector-cta-name"
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Example: Use text on button"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>
      <Select<interactiveActionTypes>
        placeholder="Select action"
        size={"large"}
        showArrow
        style={{ width: "100%" }}
        onChange={(v) => {
          setActionState(v);
          return props.onChange(
            props.index,
            [
              "dataKey",
              "value",
              "expression",
              "liveControlKey",
              "inputName",
              "defaultValue",
            ],
            []
          );

          // props.onChange(props.index, "event", v);
        }}
        options={[
          { label: "Jump To Frame", value: interactiveActionTypes.jumpToFrame },
          {
            label: "Jump To Marker",
            value: interactiveActionTypes.jumpToMarker,
          },
        ]} //.map(e => ({label: e, value: e}))}
        value={actionState}
      />
      {renderActionForm(props)}
      {hasVariantsEnabled && (
        <MainCTAButton
          change={props.change}
          onChange={props.onChange}
          index={props.index}
        />
      )}
    </>
  );
}

export function AddInteractiveGotoForm(
  props: ModsPanelCompProps<IInteractiveModGoto>
) {
  return (
    <AddInteractiveHOC<IInteractiveModGoto>
      {...props}
      renderSpecificInteractive={RenderGoToOptions}
    />
  );
}
