import { IInteractiveModOpen, modTypes } from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../../DynamicValueMod";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import { Col, Input, Row } from "antd";
import Text from "antd/lib/typography/Text";
import MainCTAButton from "../../../MainCTA/MainCTAButton";
import { rootStore } from "../../../../stores/main";
import { ExperimentOptimizationTechnique } from "../../../../API";

function renderActionForm(props: ModsPanelCompProps<IInteractiveModOpen>) {
  const hasVariantsEnabled =
    rootStore.experimentStore.selectedExperimentOptimizationTechnique &&
    rootStore.experimentStore.selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;

  return (
    <>
      <Row>
        <Col>
          <Text type="secondary">Give this CTA a name [Optional]</Text>
          {/* <InfoTooltip info="" /> */}
          <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Input
              size={"large"}
              id="connector-cta-name"
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Example: Use the text on the button"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>
      <DynamicValueMod
        change={props.change}
        onChange={(index, keys, values) => {
          return props.onChange(
            index,
            (keys as Array<keyof IInteractiveModOpen>).concat("type"),
            values.concat(modTypes.interactiveOpen)
          );
        }}
        index={props.index}
        type={DynamicValueModTypes.url}
      />
      {hasVariantsEnabled && (
        <MainCTAButton
          change={props.change}
          onChange={props.onChange}
          index={props.index}
        />
      )}
    </>
  );
}

export function AddInteractiveOpenForm(
  props: ModsPanelCompProps<IInteractiveModOpen>
) {
  return (
    <AddInteractiveHOC<IInteractiveModOpen>
      {...props}
      renderSpecificInteractive={renderActionForm}
    />
  );
}
